@import "~bootstrap";
:root {
    --bs-blue:#3b7ddd;
    --bs-blue-dark:#222e3c;
    --bs-indigo:#6610f2;
    --bs-purple:#6f42c1;
    --bs-pink:#e83e8c;
    --bs-red:#dc3545;
    --bs-orange:#fd7e14;
    --bs-yellow:#ffc107;
    --bs-green:#28a745;
    --bs-teal:#20c997;
    --bs-cyan:#17a2b8;
    --bs-white:#fff;
    --bs-gray:#6c757d;
    --bs-gray-dark:#343a40;
    --bs-primary:#3b7ddd;
    --bs-secondary:#6c757d;
    --bs-success:#28a745;
    --bs-info:#17a2b8;
    --bs-warning:#ffc107;
    --bs-danger:#dc3545;
    --bs-light:#f8f9fa;
    --bs-dark:#212529;
    --bs-font-sans-serif:"Inter","Helvetica Neue",Arial,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient:linear-gradient(180deg,hsla(0,0%,100%,0.15),hsla(0,0%,100%,0))
}
